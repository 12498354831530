import { ApolloClient, makeVar, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import {
  SESSIONSTORAGE_ACTIVE_CATEGORY,
  SESSIONSTORAGE_ACTIVE_LAYOUT,
  SESSIONSTORAGE_AUTO_CONFIRM,
  SESSIONSTORAGE_BALANCE,
  SESSIONSTORAGE_BET_LIMIT,
  SESSIONSTORAGE_CURRENCY,
  SESSIONSTORAGE_DEFAULT_CHIPS,
  SESSIONSTORAGE_TABLE_LIMIT,
  SESSIONSTORAGE_TOKEN,
  SESSIONSTORAGE_UNIT,
  SESSIONSTORAGE_USER_DATA,
} from "./constants";
import { getSessionStorageItem } from "./sessionStorage";
import { IUserData } from "../types";
import { LobbyData } from "../api/mutation/lobby_mutation";
import { json } from "stream/consumers";
import { IAllSlots } from "../api/mutation/slots_mutation";

// const token = sessionStorage.getItem(SESSIONSTORAGE_TOKEN);

// NOTE: Add getSessionStorageItem to avoid SSR issue for every refresh of the page
const user_balance = getSessionStorageItem(SESSIONSTORAGE_BALANCE);
const user_currency = getSessionStorageItem(SESSIONSTORAGE_CURRENCY);
const active_layout = getSessionStorageItem(SESSIONSTORAGE_ACTIVE_LAYOUT);
const active_category = getSessionStorageItem(SESSIONSTORAGE_ACTIVE_CATEGORY);
const user_data = JSON.parse(
  getSessionStorageItem(SESSIONSTORAGE_USER_DATA) || "{}",
);
const dowinn_token = getSessionStorageItem(SESSIONSTORAGE_TOKEN);
const bet_limit = getSessionStorageItem(SESSIONSTORAGE_BET_LIMIT);
const table_limit = getSessionStorageItem(SESSIONSTORAGE_TABLE_LIMIT);

const default_chips = JSON.parse(
  getSessionStorageItem(SESSIONSTORAGE_DEFAULT_CHIPS) || "[]",
);

const currency_rate = getSessionStorageItem("currency_rate");
const user_os = getSessionStorageItem("user_os") || "";
const user_browser = getSessionStorageItem("user_browser") || "";
const current_chips = getSessionStorageItem("current_chips") || "[]";
const current_bets = getSessionStorageItem("current_bets") || "[]";
const current_chip = getSessionStorageItem("current_chip") || "25";
const autoConfirmSession =
  getSessionStorageItem(SESSIONSTORAGE_AUTO_CONFIRM) || "false";
const accessTokenSession = getSessionStorageItem("access_token") || "";
const selectedTablesSession = getSessionStorageItem("selected_tables") || "[]";
const baseChipsSession = getSessionStorageItem("base_chips") || "[]";
const roundCountSession = getSessionStorageItem("round_count") || "[]";
const roundCountdownSession = getSessionStorageItem("count_down") || "[]";
const homeURLSession = getSessionStorageItem("home_url") || "";

const current_unit = getSessionStorageItem(SESSIONSTORAGE_UNIT) || "false";

const hasBetsSession = getSessionStorageItem("has_bets") || "[]";
// NOTE: AVOID USING ANY TYPE FOR VARIABLES
// Authority state management
export const sidebarVar = makeVar(true);
export const gameMenuVar = makeVar<string | null>(null);
export const userBalance = makeVar<string | null>(user_balance);
export const userCurrency = makeVar<string | null>(user_currency);
export const userDataVar = makeVar<IUserData>(user_data);
export const dowinnTokenVar = makeVar<string | null>(dowinn_token);
export const betLimitVar = makeVar<any>(bet_limit);
export const tableLimitVar = makeVar<any>(table_limit);
export const defaultChips = makeVar<number[]>(default_chips);
export const bacLobbyData = makeVar<LobbyData[] | []>([]);
export const slotsLobbyData = makeVar<IAllSlots[] | []>([]);
export const lobbyGameJunketVar = makeVar<string[]>([]);

export const currencyRate = makeVar<number | null>(Number(currency_rate));
export const userOS = makeVar<string>(user_os);
export const userBrowser = makeVar<string>(user_browser);
export const currentChips = makeVar<{ tableId: string; chip: number }[]>(
  JSON.parse(current_chips),
);

export const currentChip = makeVar<number>(JSON.parse(current_chip));

export const autoConfirm = makeVar<boolean>(JSON.parse(autoConfirmSession));
export const isSkeletonVar = makeVar<boolean>(false);
export const isShowNotificationVar = makeVar<boolean>(false);
export const unitCurrency = makeVar<boolean>(JSON.parse(current_unit));
export const soundActive = makeVar<boolean>(true);
export const sceneVolume = makeVar<number>(1);

export const accessToken = makeVar<string>(accessTokenSession);
export const selectedTablesVar = makeVar<ITable[]>(
  JSON.parse(selectedTablesSession),
);
export const baseChipsVar = makeVar<number[]>(JSON.parse(baseChipsSession));
export const roundCountVar = makeVar<{ tableId: string; count: number }[]>(
  JSON.parse(roundCountSession),
);
export const roundCountdownVar = makeVar<
  { tableId: string; isActive: boolean }[]
>(JSON.parse(roundCountdownSession));

export const homeURLVar = makeVar(homeURLSession);
export const latencyVar = makeVar(0);
export const hasBetsVar = makeVar<{ tableId: string; hasBet: boolean }[]>(
  JSON.parse(hasBetsSession),
);

export const selectedQualityVar = makeVar<
  { tableId: string; quality: string }[]
>([]);

type CurrentBet = {
  tableId: string;
  currentBet: {
    P: number;
    B: number;
    T: number;
    PP: number;
    BP: number;
    S6: number;
    FP: number;
    EP: number;
    L6: number;
    PB: number;
    BB: number;
    BIG: number;
    SMALL: number;
  };
};

export interface ITable {
  tableId: string;
  tableType: string;
  isLobby: boolean;
  isClosed: boolean;
}

export const currentBets = makeVar<CurrentBet[]>(JSON.parse(current_bets));

// general state management
// TODO:
export const activeCategoryVar = makeVar<string | null>(
  active_category ?? "speed_baccarat",
);
export const activeLayoutVar = makeVar<string | null>(active_layout);
export const activeCatVar = makeVar<string>(
  active_category ?? "speed_baccarat",
);

export const activeJunketBranchVar = makeVar<string | null>("all");
export const historyModeOpened = makeVar<boolean>(false);
export const dropDownOpened = makeVar<boolean>(false);
export const depositSlotModeOpened = makeVar<boolean>(false);
export const depositSlotAmount = makeVar<number>(0);
export const slotsData = makeVar<any | null>(null);
export const slotGameModalOpened = makeVar<boolean>(false);
export const slotGameURL = makeVar<string>("");

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_URL?.slice(
    0,
    process.env.REACT_APP_URL.length - 1,
  )}`,
  customFetch: fetch,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.REACT_CLIENT_URL}`,
    "Access-Control-Allow-Credentials": "true",
  },
  credentials: "include",
});

export const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          sidebarVar: {
            read() {
              return sidebarVar();
            },
          },
          gameMenuVar: {
            read() {
              return gameMenuVar();
            },
          },
          dowinnTokenVar: {
            read() {
              return dowinnTokenVar();
            },
          },
          userDataVar: {
            read() {
              return userDataVar();
            },
          },
          userBalance: {
            read() {
              return userBalance();
            },
          },
          userCurrency: {
            read() {
              return userCurrency();
            },
          },
          activeLayoutVar: {
            read() {
              return activeLayoutVar();
            },
          },
          activeCategoryVar: {
            read() {
              return activeCategoryVar();
            },
          },
          activeCatVar: {
            read() {
              return activeCategoryVar();
            },
          },
          activeJunketBranchVar: {
            read() {
              return activeJunketBranchVar();
            },
          },
          betLimitVar: {
            read() {
              return betLimitVar();
            },
          },
          tableLimitVar: {
            read() {
              return tableLimitVar();
            },
          },
          defaultChips: {
            read() {
              return defaultChips();
            },
          },
          bacLobbyData: {
            read() {
              return bacLobbyData();
            },
          },
          slotsLobbyData: {
            read() {
              return slotsLobbyData();
            },
          },
          lobbyGameJunketVar: {
            read() {
              return lobbyGameJunketVar();
            },
          },
          currencyRate: {
            read() {
              return currencyRate();
            },
          },
          userOS: {
            read() {
              return userOS();
            },
          },
          userBrowser: {
            read() {
              return userBrowser();
            },
          },
          historyModeOpened: {
            read() {
              return historyModeOpened();
            },
          },
          dropDownOpened: {
            read() {
              return dropDownOpened();
            },
          },
          depositSlotModeOpened: {
            read() {
              return depositSlotModeOpened();
            },
          },
          depositSlotAmount: {
            read() {
              return depositSlotAmount();
            },
          },
          currentChips: {
            read() {
              return currentChips();
            },
          },
          currentBets: {
            read() {
              return currentBets();
            },
          },
          currentChip: {
            read() {
              return currentChip();
            },
          },
          isSkeletonVar: {
            read() {
              return isSkeletonVar();
            },
          },
          accessToken: {
            read() {
              return accessToken();
            },
          },
          unitCurrency: {
            read() {
              return unitCurrency();
            },
          },
          soundActive: {
            read() {
              return soundActive();
            },
          },
          sceneVolume: {
            read() {
              return sceneVolume();
            },
          },
          selectedTablesVar: {
            read() {
              return selectedTablesVar();
            },
          },
          baseChipsVar: {
            read() {
              return baseChipsVar();
            },
          },
          roundCountVar: {
            read() {
              return roundCountVar();
            },
          },
          roundCountdownVar: {
            read() {
              return roundCountdownVar();
            },
          },
          homeURLVar: {
            read() {
              return homeURLVar();
            },
          },
          latencyVar: {
            read() {
              return latencyVar();
            },
          },
          hasBetsVar: {
            read() {
              return hasBetsVar();
            },
          },
          selectedQualityVar: {
            read() {
              return selectedQualityVar();
            },
          },
          slotsData: {
            read() {
              return slotsData();
            },
          },
          slotGameModalOpened: {
            read() {
              return slotGameModalOpened();
            },
          },
        },
      },
    },
  }),
});
