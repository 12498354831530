// "use server";
// // cookiesUtils.ts
// import { cookies } from "next/headers";

// // Utility function to set a cookie
// export async function setCookie(
//   key: string,
//   value: string,
//   options: { maxAge?: number } = {},
// ) {
//   const cookieStore = cookies();
//   cookieStore.set(key, value, options);
// }

// // Utility function to get a cookie
// export async function getCookie(key: string): Promise<string | undefined> {
//   const cookieStore = cookies();
//   return cookieStore.get(key)?.value;
// }

// // Utility function to remove a cookie
// export async function removeCookie(key: string) {
//   const cookieStore = cookies();
//   cookieStore.set(key, "", { maxAge: -1 });
// }

// // Utility function to handle multiple cookies at once
// export async function setMultipleCookies(
//   data: Record<string, string>,
//   options: { maxAge?: number } = {},
// ) {
//   const cookieStore = cookies();
//   for (const [key, value] of Object.entries(data)) {
//     cookieStore.set(key, value, options);
//   }
// }

// CLIENT SIDE ************************
// cookiesUtils.ts
"use client";

// Utility function to set a cookie
export function setCookie(
  key: string,
  value: string,
  options: { path?: string } = {},
) {
  let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

  // Always set the path to root to avoid path-based duplication
  cookieString += "; path=/";

  // Set SameSite attribute to Lax for better security
  cookieString += "; SameSite=Lax";

  // Set Secure flag if on HTTPS
  if (window.location.protocol === "https:") {
    cookieString += "; Secure";
  }

  document.cookie = cookieString;
}

// Utility function to get a cookie
export function getCookie(key: string): string | undefined {
  const name = encodeURIComponent(key) + "=";
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.indexOf(name) === 0) {
      return decodeURIComponent(cookie.substring(name.length, cookie.length));
    }
  }
  return undefined;
}

// Utility function to remove a cookie
export function removeCookie(key: string) {
  document.cookie = `${encodeURIComponent(key)}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}
