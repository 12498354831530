export const SESSIONSTORAGE_TOKEN = "dowinn-token";
export const SESSIONSTORAGE_LANGUAGE = "dowinn-language";
export const SESSIONSTORAGE_USER_ID = "user_id";
export const SESSIONSTORAGE_CURRENCY = "currency";
export const SESSIONSTORAGE_BALANCE = "balance";
export const SESSIONSTORAGE_USER_DATA = "user_data";
export const SESSIONSTORAGE_ACTIVE_LAYOUT = "active_layout";
export const SESSIONSTORAGE_ACTIVE_CATEGORY = "active_category";
export const SESSIONSTORAGE_TABLE_LIMIT = "table_limit";
export const SESSIONSTORAGE_BET_LIMIT = "bet_limit";
export const SESSIONSTORAGE_AGENT_ID = "agent_id";
export const SESSIONSTORAGE_USER_TYPE = "user_type";
export const SESSIONSTORAGE_DEFAULT_CHIPS = "default_chips";
export const SESSIONSTORAGE_TABLE_BETS = "table_bets";
export const SESSIONSTORAGE_AUTO_CONFIRM = "auto_confirm";

export const SESSIONSTORAGE_UNIT = "unit";
